.chh-menu .mat-mdc-menu-item-text {
  letter-spacing: normal !important;
  /*border: 3px solid green !important;*/
}

.chh-button, button {
  letter-spacing: normal !important;
  white-space: nowrap;
  /*border: 3px solid green !important;*/
}

.chh-mat-tab-group .mdc-tab__text-label {
  letter-spacing: normal !important;
  /*border: 3px solid green !important;*/
}

.chh-form-field {
  padding-bottom: 15px;
  /*border: 3px solid green !important;*/
}

.chh-form-field-with-error {
  padding-bottom: 25px;
  /*border: 3px solid green !important;*/
}

.chh-form-field .mat-mdc-form-field-subscript-wrapper {
  height: 0px;
  /*border: 1px solid blue !important;*/
}

mat-list-option {
  border-radius: 3px !important;
}

mat-list-option[aria-selected=true] {
  background: rgb(255, 106, 0) !important;
  color: white !important;
}

mat-list-option[aria-selected=true] > span > span {
  color: white !important;
}

mat-list-option[aria-selected=true] .mdc-radio__outer-circle {
  background: white !important;
  border: 5px solid white !important;
}

mat-list-option[aria-selected=true] .mdc-radio__inner-circle {
  background: rgb(255, 106, 0) !important;
  border: 5px solid rgb(255, 106, 0) !important;
}

.chh-dialog-container {
  padding: 25px !important;
}

.snack-bar-custom {
  /*border: 5px solid red;
  position:absolute;
  top: 100px;*/
}

.mat-mdc-progress-spinner circle, .mat-mdc-spinner circle {
  /*stroke: #3fb53f;*/
}

.nav-spinner circle {
  stroke: #ffffff !important;
}

/*=================================================*/
/* CHH TEXT ALIGNMENT*/
/*=================================================*/
.chh-align-right {
  text-align: right !important;
}

/*=================================================*/
/*=================================================*/
/* CHH LIST SELECTION*/
/*=================================================*/
.chh-mat-selection-list {
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  overflow-y: scroll;
}

/*=================================================*/
/*=================================================*/
/* CHH ICONS */
/*=================================================*/
.clickable-icon:hover {
  cursor: pointer !important;
  /*background-color: #fafafa !important;*/
  background-color: #e6e6e6 !important;
}

.chh-icon {
  margin-left: 20px;
  /*padding: 5px;*/
  width: 45px !important;
  height: 45px !important;
  font-size: 40px !important;
  border-radius: 6px;
}

/*=================================================*/
/*=================================================*/
/* CHH LIST SELECTION */
/*=================================================*/
.chh-list-item-row {
  display: flex;
  align-items: end;
  background-color: #fff;
  padding-left: 10px;
  padding-right: 10px;
  /*padding-top: 10px;
  padding-bottom: 10px;*/
  border: 1px solid #fff;
  /*height: 40px;*/
}

.chh-list-item-row-hover {
  background-color: #e4e4e4;
  /*background-color: #808080;*/
  cursor: pointer;
}

.chh-list-item-row-selected {
  background-color: rgb(255, 106, 0) !important;
  /*background-color: #808080;*/
  color: #fff;
  cursor: pointer;
  border: 1px solid rgb(255, 106, 0);
}

.chh-list-item-row-selected .chh-list-radio .mdc-radio__outer-circle {
  background: white !important;
  border: 5px solid white !important;
}

.chh-list-item-row-selected .chh-list-radio .mdc-radio__inner-circle {
  background: rgb(255, 106, 0) !important;
  border: 5px solid rgb(255, 106, 0) !important;
}

.chh-list-item-first-column {
  border-radius: 6px 0px 0px 6px !important;
  /*background: black !important;*/
}

.chh-list-item-last-column {
  border-radius: 0px 6px 6px 0px !important;
}

/*=================================================*/
/*=================================================*/
/* CHH ELEMENT FADE IN FADE OUT ANIMATIONS */
/*=================================================*/
.elementToFadeInAndOut {
  opacity: 1;
  animation: fade 1s linear;
}

.elementToFadeIn2Sec {
  opacity: 1;
  animation: fade 2s linear;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.elementToFadeOut {
  opacity: 1;
  animation: fadeout 1s forwards;
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.elementSlideInFromLeft {
  animation: 1s ease-out 0s 1 slideInFromLeft;
}

@keyframes slideInFromLeft {
  0% {
    /*transform: translateX(-5%);*/
    transform: translateY(-5%);
  }
  100% {
    /*transform: translateX(0);*/
    transform: translateY(0);
  }
}
.product-image-swipe-left {
  animation: spinswipe 0.3s 0s forwards reverse;
}

@keyframes spinswipe {
  8.33%, 25% {
    /*left: -100px;*/
    /*position: absolute;*/
    opacity: 1;
    z-index: 1;
    transform: scale(1);
  }
  26% {
    /*transform: translateX(0);*/
    /*left: -200px;*/
    transform: translate(-120%, 0px);
  }
  /*  0%, 33.33%, 100% {
      opacity: 0;
      z-index: 0;
      transform: rotate(45deg);
    }*/
}
/*=================================================*/
/*=================================================*/
/* CHH TEXT ANIMATIONS */
/*=================================================*/
.text-fade-in-out {
  animation-name: fadeIn;
  animation-duration: 2s;
}

.text-fade-in-out2 {
  animation-name: fadeIn2;
  animation-duration: 2s;
}

@keyframes fadeIn {
  0% {
    color: white;
  }
  100% {
    color: black;
  }
}
@keyframes fadeIn2 {
  0% {
    color: white;
  }
  100% {
    color: black;
  }
}
/*=================================================*/
/*=================================================*/
/* CHH TEXT BOXES */
/*=================================================*/
.chh-textbox-50 {
  width: 50px;
}

.chh-textbox-75 {
  width: 75px;
}

.chh-textbox-100 {
  width: 100px;
}

.chh-textbox-125 {
  width: 125px;
}

.chh-textbox-150 {
  width: 150px;
}

.chh-textbox-175 {
  width: 175px;
}

.chh-textbox-200 {
  width: 200px;
}

.chh-textbox-225 {
  width: 225px;
}

.chh-textbox-250 {
  width: 250px;
}

.chh-textbox-275 {
  width: 275px;
}

.chh-textbox-300 {
  width: 300px;
}

.chh-textbox-400 {
  width: 400px;
}

.chh-textbox-500 {
  width: 500px;
}

.chh-textbox-600 {
  width: 600px;
}

.chh-textbox-700 {
  width: 700px;
}

.chh-textbox-800 {
  width: 800px;
}

/*=================================================*/
/*=================================================*/
/* CHH PHOTO VIEW ZOOM DIALOG */
/*=================================================*/
.photo-zoom-dialog {
  z-index: 2147483647 !important;
  position: absolute !important;
  top: 20px !important;
  width: 41vw;
  height: 98vh;
  text-align: center;
}

@media only screen and (max-width: 1280px) {
  .photo-zoom-dialog {
    z-index: 2147483647 !important;
    position: absolute !important;
    top: 20px !important;
    width: 99vw !important;
    /*    height: 98vh !important;
    */
    text-align: center;
  }
}
/*=================================================*/
