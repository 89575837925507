/* You can add global styles to this file, and also import other style files */
/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

html {
  height: 100%;
  min-height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal !important;
}

app-nav-menu {
  border: 0px solid red;
}

.container {
  /*padding-top: 85px;*/
  height: max-content;
  width: 100%;
  /*width: 99vw !important;*/
  margin-left: 0;
  margin-right: 0;
  /*  display:flex;
  justify-content:center;*/
  border: 0px solid red;
}

.mat-button-toggle-checked {
  background-color: #ff9028 !important;
  color: #ffffff !important;
}

.toUpperCase {
  text-transform: uppercase;
}

.cell-align-right {
  text-align: right;
}

/*============================================================*/
/* SNACK BAR CLASSES*/
/*============================================================*/
/*mdc-snackbar*/
.centerSnackbar {
  position: absolute !important;
  top: 45% !important;
  left: 35% !important;
  -moz-transform: translateX(-45%) translateY(-35%) !important;
  -webkit-transform: translateX(-45%) translateY(-35%) !important;
  transform: translateX(-45%) translateY(-35%) !important;
  /*  margin-right: 500px !important;
    margin-bottom: 125px !important;
  */
}

.productCodeSnackBar {
  margin-right: 170px !important;
  margin-bottom: 50px !important;
}

.addToOrderSnackBar {
  margin-right: 500px !important;
  margin-bottom: 125px !important;
}

.snack-bar-error {
  margin-right: 500px !important;
  margin-bottom: 125px !important;
  background-color: red !important;
  color: #fff !important;
}

/* Close button on snack bar error*/
.snack-bar-error span {
  color: white;
}

.snack-bar-warning {
  margin-right: 500px !important;
  margin-bottom: 125px !important;
  background-color: #fa8b3d !important;
  color: #fff !important;
}

.snack-bar-right-panel-warning {
  margin-right: 200px !important;
  margin-bottom: 400px !important;
  background-color: #fa8b3d !important;
  color: #fff !important;
}

.mat-mdc-tooltip {
  font-size: 20px !important;
}

.mat-mdc-tooltip div {
  font-size: 20px !important;
  line-height: 1.3 !important;
}

/*.chh-tooltip-max-width*/
.chh-tooltip-max-width {
  font-size: 20px !important;
  width: max-content !important;
  max-width: unset !important;
}

/*.chh-tooltip-max-width*/
.chh-tooltip-max-width div {
  font-size: 20px !important;
  width: max-content !important;
  max-width: unset !important;
}

.help-icon {
  margin: 5px;
}

/*===========================================================*/
/* MAT TAB STYLES*/
/*.mat-mdc-tab-group .mat-tab-label-active {
  background-color: #3454B2 !important;
  opacity: 1 !important;
}
*/
.mat-mdc-tab-header {
  width: 100%;
}

.mat-mdc-tab-label-container {
  width: max-content;
}

.mat-mdc-tab {
  width: 200px;
}

.chh-mat-tab-group .mdc-tab--active {
  background-color: #3454b2 !important;
  opacity: 1 !important;
}

.chh-mat-tab-group .mat-mdc-tab-label {
  background-color: navy !important;
  color: white !important;
}

.chh-mat-tab-group .mat-mdc-tab .mdc-tab__text-label {
  color: white !important;
  opacity: 0.9 !important;
  font-size: 1.1rem;
}

.chh-mat-tab-group .mdc-tab--active .mdc-tab__text-label {
  color: white !important;
  opacity: 1 !important;
}

.mdc-tab-indicator__content--underline {
  background-color: aqua !important;
  border-top: 3px solid aqua !important;
}

.chh-mat-tab-group .mat-tab-header {
  border-radius: 6px !important;
  background-color: navy !important;
}

.chh-mat-tab-group .mat-mdc-tab-header {
  border-radius: 6px !important;
  background-color: navy !important;
}

.mdc-circular-progress__indeterminate-circle-graphic circle {
  stroke: navy;
}

/*===========================================================*/
/*@use '../node_modules/@angular/material/' as mat;*/
/*@mixin testSize($size){
    font-size: $size;
}

body {

    @include testSize(30px);

}

$indigo-palette: ( '50': 'e0e2f0',
    '100': 'b3b6d8',
    '200': '8086bf',
    '300': '4d55a5',
    '400': '263091',
    '500': '000c7e',
    '600': '000a76',
    '700': '00086b',
    '800': '000661',
    '900': '00034e',
    'A100': '8181ff',
    'A200': '4e4eff',
    'A400': '1b1bff',
    'A700': '0202ff',
  contrast: ( 50: rgba(black, 0.87), 100: rgba(black, 0.87), 200: rgba(black, 0.87), 300: white,
));*/
/*$my-primary: mat.define-palette($indigo-palette, 500);
$my-accent: mat.define-palette($indigo-palette, A200, A100, A400);*/
.flex-center {
  display: flex;
  align-items: center;
}
